import React from "react";
import Pattern from "../../../images/svg/panel_long_1.svg";
import './../index.scss';
import './index.scss';
import { Parallax } from "react-parallax"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { CaptionImg } from "../../CaptionImg/captionImg"

export const Section4 = ({ bgImgSrc, reverse, imgSrc, caption, text, extended }) => {
  const isMobile = useWindowSize().width <= 768;

  return (
    <div className={`m-main__section m-section__4 ${extended ? 'm-main__section--extend' : '' }`}>
      <div className="m-section__svg-pattern m-section__4-svg"><Pattern /></div>
      <div className="m-section__4-container">
        <div className="m-section__4-bg">
          <Parallax bgImage={bgImgSrc} strength={500}>
            {isMobile && (<div style={{ height: '80vh' }} />)}
          </Parallax>
        </div>
        <div className="m-section__4-content">
          <div className="m-section__4-img">
            <CaptionImg imgSrc={imgSrc}
                        customClass={'m-section__4-img'}
                        caption={caption}
            />
          </div>
          <div className={`m-section__section-text ${reverse ? 'm-section__section-text--reverse' : ''}`}>
            <p className="m-main__section-text" dangerouslySetInnerHTML={{ __html: text }}></p>
          </div>
        </div>
      </div>
      </div>
    )

  {/*<div className="m-section__svg-pattern"><Pattern /></div>*/}
      {/*<div className="m-section__4-svg"><Pattern /></div>*/}
      {/*<div className={'m-main__section m-section__4-bg'}><Parallax bgImage={bgImgSrc} strength={500} /></div>*/}
      {/*<div className="m-section__4-container">*/}
      {/*  <div className="m-section__4-img">*/}
      {/*    <img className="m-section__4-img" src={'https://shopiplace.com/wp-content/uploads/2018/06/wooden-beer-mug-1.jpg'} />*/}
      {/*  </div>*/}
      {/*  <div className={`m-section__section-text ${reverse ? 'm-section__section-text--reverse' : ''}`}>*/}
      {/*    <p className="m-main__section-text">Despite their great contribution to the economic development of the region, the Mennonites from the beginning faced growing hostility from representatives of other faiths. Already at the end of the 16th century, the Lutheran authorities of Toruń expelled them from the Vistula villages belonging to the city, incl. from Old Toruń and Górsko, which were leased in 1574. The 17th century brought further harassment, this time from the Catholic clergy and officials forcing extraordinary tributes. In an atmosphere of danger, the Vistula Mennonites sought the same privileges from the Polish kings that protected their brothers from Żuławy. In the first half of the 18th century, increasing religious restrictions and the economic crisis forced many Mennonite families from the vicinity of Chełmno, Świecie, Grudziądz and Toruń to emigrate. They established new Mennonite colonies on the Nemunas near Tylża, on the lower Noteć (Głęboczek and Błotnica), in Jeziorek near Tuchola, as well as in Mazowsze (Kazuń) and Powiśle (near Ryjewo).*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</div>*/}
}
