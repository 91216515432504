import React from "react";
import './index.scss';

export const Section6 = ({ text, imgSrc }) => {
  return (
    <div className="m-main__section m-section__6">
      <div className="m-section__6-container">
        {text ?
          <p className="m-main__section-text">
            {text}
          </p>
          :
          <img className="m-section__6-img" src={imgSrc} />
        }
      </div>
    </div>
  )
}
