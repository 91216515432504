import React, { useRef, useState, useEffect } from "react";
import Layout from "../components/layout";
import "./index.scss";
import Pattern from "../images/svg/panel_long_1.svg";
import { graphql } from "gatsby";
import { Section1 } from "../components/Sections/Section1/section1";
import { Section2 } from "../components/Sections/Section2/section2"
import { Section3 } from "../components/Sections/Section3/section3";
import { Section4 } from "../components/Sections/Section4/section4";
import { Section5 } from "../components/Sections/Section5/section5";
import { ChapterTitle } from "../components/Sections/ChapterTitle/chapterTitle"
import { SubsectionTitle } from "../components/Sections/SubsectionTitle/subsectionTitle";
import { getResource } from "../utils/getResource"
import { Section6 } from "../components/Sections/Section6/section6"
import { PageNavigation } from "../components/PageNavigation/pageNavigation"
import { Section3b } from "../components/Sections/Section3/section3b"
import { Section3alt } from "../components/Sections/Section3/section3alt"

const Index = ({ data, location }) => {
  const pageResources = data.allMarkdownRemark.edges;
  const historyRefs = useRef([])
  const [pageRefs, setPageRefs] = useState([])

  useEffect(() => {
    const pageRefsArr = historyRefs.current.map(ref => ({ id: ref.id, offset: ref.offsetTop, height: ref.offsetHeight }))

    setPageRefs(pageRefsArr)
  }, [])

  return (
    <Layout location={location} pageRefs={pageRefs}>
      <div className="m-main__content">
          <div className="m-main__section-pattern">
            <Pattern />
          </div>
          <div className="m-section__history">
            <ChapterTitle text={getResource(pageResources, 'historia-title').content} bgImage={getResource(pageResources, 'historia-title').image} />
            <div ref={el => historyRefs.current[0] = el} id="exhibition-history-beginning" className={'section'}>
              <SubsectionTitle text={getResource(pageResources, 'historia-subtitle-1').content} />
              <Section3
                imgLeftSrc={getResource(pageResources, 'historia-text-1').image}
                imgRightSrc={getResource(pageResources, 'historia-text-2').image}
                txtLeft={getResource(pageResources, 'historia-text-1').content}
                txtRight={getResource(pageResources, 'historia-text-2').content}
                imgLeftCaption={getResource(pageResources, 'historia-text-1').imageContent}
                imgRightCaption={getResource(pageResources, 'historia-text-2').imageContent}
                imgFull
              />
              <Section2 option={'textOnly'} text={getResource(pageResources, 'historia-text-3').content} bgImg={getResource(pageResources, 'historia-text-3').image} />
            </div>
            <div ref={el => historyRefs.current[1] = el} id="exhibition-history-from" className={'section'}>
              <SubsectionTitle text={getResource(pageResources, 'historia-subtitle-2').content} />
              <Section1 text={getResource(pageResources, 'historia-text-4').content} imgSrc={getResource(pageResources, 'historia-text-4').image} imgCaption={getResource(pageResources, 'historia-text-4').imageContent} isReversed centered />
              <Section5 imgSrc={getResource(pageResources, 'historia-img-1-1').image} caption={getResource(pageResources, 'historia-img-1-1').imageContent} />
              <Section1 centered text={getResource(pageResources, 'historia-text-5').content} imgSrc={getResource(pageResources, 'historia-text-5').image} imgCaption={getResource(pageResources, 'historia-text-5').imageContent} />
            </div>
            <div ref={el => historyRefs.current[2] = el} id="exhibition-history-why-poland" className={'section'}>
              <SubsectionTitle text={getResource(pageResources, 'historia-subtitle-3').content} />
              <Section1 text={getResource(pageResources, 'historia-text-6').content} imgCaption={getResource(pageResources, 'historia-text-6').imageContent} imgSrc={getResource(pageResources, 'historia-text-6').image} isReversed/>
              <Section2 customSectionClass="m-section__history-img-abs" option={'imgOnly'} imgSrc={getResource(pageResources, 'historia-img-1').image} bgImg={getResource(pageResources, 'historia-img-1').bgImage} caption={getResource(pageResources, 'historia-img-1').imageContent} />
              <Section6 text={getResource(pageResources, 'historia-text-7').content} />
              <Section2 option={'textOnly'} text={getResource(pageResources, 'historia-text-8').content} bgImg={getResource(pageResources, 'historia-text-8').image} />
            </div>
            <div ref={el => historyRefs.current[3] = el} id="exhibition-history-zulawy" className={'section'}>
              <SubsectionTitle text={getResource(pageResources, 'historia-subtitle-4').content} />
              <Section4 bgImgSrc={getResource(pageResources, 'historia-text-9').bgImage} caption={getResource(pageResources, 'historia-text-9').imageContent} imgSrc={getResource(pageResources, 'historia-text-9').image} text={getResource(pageResources, 'historia-text-9').content} />
              <Section1 text={getResource(pageResources, 'historia-text-10').content} imgSrc={getResource(pageResources, 'historia-text-10').image} imgCaption={getResource(pageResources, 'historia-text-10').imageContent} isReversed centered />
              <Section2 option={'textOnly'} text={getResource(pageResources, 'historia-text-11').content} imgSrc={getResource(pageResources, 'historia-text-11').image} bgImg={getResource(pageResources, 'historia-text-11').bgImage} isReversed />
              <Section1 imgCaption={getResource(pageResources, 'historia-text-12').imageContent} text={getResource(pageResources, 'historia-text-12').content} imgSrc={getResource(pageResources, 'historia-text-12').image} centered />
              <Section1 text={getResource(pageResources, 'historia-text-13').content} imgSrc={getResource(pageResources, 'historia-text-13').image} imgCaption={getResource(pageResources, 'historia-text-13').imageContent} centered isReversed />
              <Section2 option={'twoColumns'} text={getResource(pageResources, 'historia-text-14').content} imgSrc={getResource(pageResources, 'historia-text-14').image} bgImg={getResource(pageResources, 'historia-text-14').bgImage} caption={getResource(pageResources, 'historia-text-14').imageContent} isReversed />
              <Section1
                imgCaption={getResource(pageResources, 'historia-text-15').imageContent}
                text={getResource(pageResources, 'historia-text-15').content}
                imgSrc={getResource(pageResources, 'historia-text-15').image}
                centered
                customImgStyle={{ height: '50%', width: '50%' }}
              />
              <Section2 option={'twoColumns'} text={getResource(pageResources, 'historia-text-17').content} bgImg={getResource(pageResources, 'historia-text-17').bgImage} imgSrc={getResource(pageResources, 'historia-text-17').image} caption={getResource(pageResources, 'historia-text-17').imageContent} isReversed />
              <Section3
                imgLeftSrc={getResource(pageResources, 'historia-text-18').image}
                imgRightSrc={getResource(pageResources, 'historia-text-19').image}
                txtLeft={getResource(pageResources, 'historia-text-18').content}
                txtRight={getResource(pageResources, 'historia-text-19').content}
                imgLeftCaption={getResource(pageResources, 'historia-text-18').imageContent}
                imgRightCaption={getResource(pageResources, 'historia-text-19').imageContent}
                imgFull
                imgRightFull
              />
              <Section2 option={'textOnly'} text={getResource(pageResources, 'historia-text-20').content} bgImg={getResource(pageResources, 'historia-text-20').bgImage} />
            </div>
            <div ref={el => historyRefs.current[4] = el} id="exhibition-history-vistula" className={'section'}>
              <SubsectionTitle noBottomMargin text={getResource(pageResources, 'historia-subtitle-5').content} />
              <SubsectionTitle smallFont text={getResource(pageResources, 'historia-subtitle-5').imageContent} textOnly />
              <Section2 option={'twoColumns'} text={getResource(pageResources, 'historia-text-21').content} imgSrc={getResource(pageResources, 'historia-text-21').image} caption={getResource(pageResources, 'historia-text-21').imageContent} bgImg={getResource(pageResources, 'historia-text-21').bgImage} isReversed />
              <Section4 bgImgSrc={getResource(pageResources, 'historia-text-22').bgImage} imgSrc={getResource(pageResources, 'historia-text-22').image} text={getResource(pageResources, 'historia-text-22').content} caption={getResource(pageResources, 'historia-text-22').imageContent}/>
              <Section3
                imgLeftSrc={getResource(pageResources, 'historia-text-23').image}
                imgRightSrc={getResource(pageResources, 'historia-text-24').image}
                txtLeft={getResource(pageResources, 'historia-text-23').content}
                txtRight={getResource(pageResources, 'historia-text-24').content}
                imgLeftCaption={getResource(pageResources, 'historia-text-23').imageContent}
                imgRightCaption={getResource(pageResources, 'historia-text-24').imageContent}
              />
              <Section2 option={'twoColumns'} text={getResource(pageResources, 'historia-text-25').content} caption={getResource(pageResources, 'historia-text-25').imageContent} imgSrc={getResource(pageResources, 'historia-text-25').image} bgImg={getResource(pageResources, 'historia-text-25').bgImage} isReversed />
              <Section1 text={getResource(pageResources, 'historia-text-26').content} centered />
              <Section5 imgSrc={getResource(pageResources, 'historia-text-31').image} caption={getResource(pageResources, 'historia-text-31').imageContent} />
              <Section1 text={getResource(pageResources, 'historia-text-27').content} centered isReversed />
            </div>
            <div ref={el => historyRefs.current[5] = el} id="exhibition-history-mazovia" className={'section'}>
              <SubsectionTitle text={getResource(pageResources, 'historia-subtitle-6').content} />
              <Section1 text={getResource(pageResources, 'historia-text-28').content} imgSrc={getResource(pageResources, 'historia-text-28').image} imgCaption={getResource(pageResources, 'historia-text-28').imageContent} centered isReversed />
              <Section3b
                imgLeftSrc={getResource(pageResources, 'historia-text-29').image}
                imgRightSrc={getResource(pageResources, 'historia-text-30').image}
                txtLeft={getResource(pageResources, 'historia-text-29').content}
                txtRight={getResource(pageResources, 'historia-text-30').content}
                imgLeftCaption={getResource(pageResources, 'historia-text-29').imageContent}
                imgRightCaption={getResource(pageResources, 'historia-text-30').imageContent}
              />
              <Section5 customSectionClass="m-section__history-parallax-30" imgSrc={getResource(pageResources, 'historia-text-31-1').bgImage} caption={getResource(pageResources, 'historia-text-31-1').imageContent} />
              <Section3alt
                imgLeftSrc={getResource(pageResources, 'historia-img-2').image}
                captionLeft={getResource(pageResources, 'historia-img-2').imageContent}
                imgRightSrc={getResource(pageResources, 'historia-img-3').image}
                captionRightTop={getResource(pageResources, 'historia-img-3').imageContent}
                txtLeft={getResource(pageResources, 'historia-text-31').content}
                imgRightBottomSrc={getResource(pageResources, 'historia-img-4').image}
                caption={getResource(pageResources, 'historia-img-4').imageContent}
              />
              <Section2 option={'twoColumns'}
                        text={getResource(pageResources, 'historia-text-32').content}
                        imgSrc={getResource(pageResources, 'historia-text-32').image}
                        isReversed
                        imgFull
                        customClass={'m-history__section-img'}
                        bgImg={getResource(pageResources, 'historia-text-32').bgImage}
                        caption={getResource(pageResources, 'historia-text-32').imageContent}
              />
            </div>
            <PageNavigation nextOnly nextItem={'exhibition-values'} />
          </div>
        </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
query($language: String) {
  allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "wystawa-item"}, language: { eq: $language }}}
  ) {
    edges {
      node {
        frontmatter {
          title
          language
          order
          content
          image
          imageContent
          bgImage
        }
      }
    }
  }
}`
