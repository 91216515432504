import React from "react";
import Pattern from "../../../images/panel_long_1.png";
import './index.scss';
import { CaptionImg } from "../../CaptionImg/captionImg"

export const Section3alt = ({ imgLeftSrc, imgRightSrc, txtLeft, imgRightBottomSrc, caption, captionLeft, captionRightTop }) => {
  return (
    <div className="m-main__section m-section__3">
      <div className={'m-section__3-bg'} style={{ backgroundImage: `url(${Pattern})` }}></div>
      <div className="m-section__3-content m-section__3b-content">
        <div className="m-section__3-left m-section__3-alt">
          <p className="m-main__section-text">
            {txtLeft}
          </p>
          <CaptionImg imgSrc={imgLeftSrc}
                      customClass="m-section__3-img"
                      caption={captionLeft}
          />
        </div>
        <div className="m-section__3-alt">
          <CaptionImg imgSrc={imgRightSrc}
                      customClass="m-section__3-img"
                      caption={captionRightTop}
          />
          <CaptionImg imgSrc={imgRightBottomSrc}
                      customClass="m-section__3-img"
                      caption={caption}
          />
        </div>
      </div>
    </div>
  )
}
