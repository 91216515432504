import React from 'react';
import './index.scss';
import PanelShortLeft from "../../../images/belka_rozdzialy_left.png";
import PanelShortRight from "../../../images/belka_rozdzialy_right.png";

export const SubsectionTitle = ({ anchor, text, textOnly, smallFont, noBottomMargin }) => {
  return (
    <div id={anchor} className={`m-main__section--small m-section__subsection-title ${textOnly ? 'm-section__subsection-title--wide' : ''} ${noBottomMargin ? 'm-section__subsection-no-bottom' : ''}`}>
      {!textOnly && (
        <div className="m-section__subsection-icon ">
          <img className="m-section__subsection-img" src={PanelShortLeft} />
        </div>
      )}
      <h1 className={`m-main__section-title--sub ${smallFont ? 'm-main__section-title--sub-small' : ''}`}>{text}</h1>
      {!textOnly && (
        <div className="m-section__subsection-icon">
          <img className="m-section__subsection-img" src={PanelShortRight} />
        </div>
      )}
    </div>
  )
}
